<template>
  <div :style="{ height: vh + 'px', width: vw + 'px' }" class="client-layout">
    <div class="app-bar">
      <AppBar />
    </div>
    <div :style="{ height: +vh - 120 + 'px', width: vw + 'px' }" class="body">
      <router-view />
    </div>
    <div class="bottom-navigation-bar">
      <BottomNavigationBar />
    </div>
  </div>
</template>

<script>
import AppBar from "./src/appBar";
import BottomNavigationBar from "./src/bottomNavigationBar";
import { useWindownSize } from "@/helpers";
import { onUnmounted } from "vue";
export default {
  components: { AppBar, BottomNavigationBar },
  setup() {
    const { vw, vh, remove } = useWindownSize();
    onUnmounted(() => {
      remove();
    });
    return {
      vw,
      vh,
      remove
    };
  }
};
</script>

<style lang="scss" scoped>
.client-layout {
  position: relative;
  overflow: hidden;
  .app-bar {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
  }
  .bottom-navigation-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .body {
    height: 100%;
    overflow: auto;
  }
}
</style>
