<template>
  <div class="app-bar__content px-3">
    <img width="30" src="@/assets/logo-small.svg" alt />
    <span class="title ml-2">Truy xuất nguồn gốc</span>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.app-bar__content {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid $brand-primary;
  @include flex-start();
  .title {
    @include text-styles(17px, 700, $brand-primary);
    text-transform: uppercase;
  }
}
</style>
