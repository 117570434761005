<template>
  <div class="bottom-navigation-bar__content px-2">
    <router-link
      :exact="true"
      active-class="link_item_active"
      exact-active-class="link_item_active"
      class="link3"
      :to="{ name: ERoutesName.HOME, query: { token } }"
      >Tổng Quan</router-link
    >
    <router-link
      v-if="showFarmingDiary"
      active-class="link_item_active"
      exact-active-class="link_item_active"
      class="link3"
      :to="{ name: ERoutesName.DIARY }"
      >Nhật Ký</router-link
    >
    <router-link
      v-if="showDiseaseSituations"
      exact-active-class="link_item_active"
      active-class="link_item_active"
      class="link3"
      :to="{ name: ERoutesName.DISEASE }"
      >Dịch Bệnh</router-link
    >
    <router-link class="link3" :to="{ name: ERoutesName.MORE_INFO }"
      >Thông Tin</router-link
    >
  </div>
</template>

<script>
import { ERoutesName } from "@/router";
import { get } from "lodash";
import jwtDecode from "jwt-decode";

export default {
  setup() {
    const token = localStorage.getItem("x-token");
    const res = jwtDecode(String(token));
    const showDiseaseSituations = get(res, "showDiseaseSituations", false);
    const showFarmingDiary = get(res, "showFarmingDiary", false);
    return { ERoutesName, token, showDiseaseSituations, showFarmingDiary };
  }
};
</script>

<style lang="scss" scoped>
.bottom-navigation-bar__content {
  width: 100%;
  height: 60px;
  background-color: $brand-primary;
  @include flex-between();
  justify-content: space-around;
}
</style>
<style lang="scss">
.link3 {
  border-radius: 4px;
  text-decoration: none;
  padding: 7px 5px;
  text-align: center;
  &:hover {
    color: $brand-primary !important;
  }
  @include text-styles(15px, 600, white);
  &.router-link-exact-active {
    background-color: white;
    color: $brand-primary;
  }
}
.link_item_active {
  background-color: white;
  color: $brand-primary;
}
</style>
